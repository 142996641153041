import type {
  ConversationId,
  MessageContent,
  SendMessageServiceParams,
  SendTypingServiceParams
} from "@chatscope/use-chat";
import {
  ChatMessage,
  Conversation,
  ConversationRole,
  IStorage,
  MessageContentType,
  MessageDirection,
  MessageEvent,
  MessageStatus,
  Participant,
  Presence,
  TypingUsersList,
  UpdateState,
  User,
  UserStatus,
  UserTypingEvent
} from "@chatscope/use-chat";
import {BaseChatAdapter} from "./BaseChatAdapter";
import type {Socket} from "socket.io-client";
import {io} from "socket.io-client";
import {nanoid} from "nanoid";
import {onActions} from "./event-handlers/onActions";
import {onConversationStateChanged} from "./event-handlers/onConversationStateChanged";
import newMessageSnd from "../assets/sounds/pop.wav";

export type InitParams = {
  server: string;
  api: string;
  onEvent?: (event:any) => void;
  autoAccept?: boolean;
}

export type ConnectParams = {
  server: string;
}

export type RegisterParams = {
  id: string;
  username: string;
  avatar: string;
  status: UserStatus;
}

export type RegisterResponse = {
  result: {
    id: string;
    username?: string;
    avatar?: string;
    status: UserStatus;
  },
  inHours?:boolean;
  actions: any;
}

export type GetConversationResponse = {
  items: {
    id: string;
    participants: string[];
    readonly: boolean;
    data: ConversationData;
    messages: { id: string, content: MessageContent<MessageContentType.TextHtml>, senderId: string }[];
  }[];
}

export type CloseConversationRequest = {
  conversationId: string;
}

export type CloseConversationResponse = {
  result: boolean;
}

export type LoginParams = {
  username: string;
  password: string;
}

export type LoginResult = {
  result: boolean;
  accessToken: string;
}

export type ConversationData = {
  state: number; // ConversationState
  type: number;  // ConversationType 
}

export type AcceptConversationRequest = {
  conversationId: ConversationId;
}

export type RejectConversationRequest = {
  conversationId: ConversationId;
}

export type AcceptConversationResponse = {
  result: boolean;
}

export type RejectConversationResponse = {
  result: boolean;
  actions: any;
}

export type UserType = "user" | "widget";
export type UserData = {
  type: UserType;
}

export class DiChatAdapter extends BaseChatAdapter {

  private socket?: Socket;
  private initialized: boolean = false;
  private server: string = "";
  private api: string = "";
  private accessToken: string = "";
  private registered: boolean = false;
  protected onEvent?: (event:any) => void;
  protected autoAccept?: boolean = false;
  private newMessageAudio: HTMLAudioElement;
  
  constructor(storage: IStorage, updateState: UpdateState) {
    super(storage, updateState);
    this.newMessageAudio = new Audio(newMessageSnd);
  }

  /**
   * Acts as a "constructor"
   */
  init({server, api, onEvent, autoAccept}: InitParams): DiChatAdapter {

    this.server = server;
    this.api = api;
    this.initialized = true;
    this.onEvent = onEvent;
    this.autoAccept = autoAccept;
    
    return this;
  };

  async login(params: LoginParams): Promise<LoginResult> {

    if (!this.initialized) {
      throw "Not initialized. Please use the initialize method first";
    }

    try {
      const res = await fetch(`${this.api}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params),
        credentials: "include"
      });

      if (res.body) {

        const resObj = await res.json();
        this.accessToken = resObj.accessToken;
        return {
          result: true,
          accessToken: this.accessToken
        };

      } else {
        return {
          result: false, accessToken: ""
        };
      }
    } catch (err) {
      return {
        result: false, accessToken: ""
      };
    }

  }

  async logout(): Promise<any> {

    try {

      return fetch(`${this.api}/logout`, {
        method: "POST",
        credentials: "include"
      });

    } catch (err) {
      return {
        result: false
      };
    }

  }

  connect(): Promise<DiChatAdapter> {

    return new Promise((resolve, reject) => {

      this.socket = io(this.server, {
        transports: ["websocket"],
        withCredentials: true,
        auth: {accessToken: this.accessToken}
      });
      this.socket.on("connect", () => {

        resolve(this);

      });

      this.socket.on("connect_error", (err) => {
        
        reject(err);

      });
      
      this.socket.on("ConversationStateChanged", (evt)  => onConversationStateChanged.call(this, evt));
      
      this.socket.on("UserStateChanged", (evt) => {
        console.log("UserStateChanged", evt);
        const state = evt.state;
        const eventUser = evt.user;
        
        if (state === /*UserState.Connected*/ 1) {
          
          // Może się zdarzyć, że taki user już istnieje w state
          // wtedy trzeba go najpierw usunąć
          const existedUser = this.storage?.getUser(eventUser.id);
          if( existedUser ) {
            this.storage?.removeUser(eventUser.id);
          } 
            
          // Dodajemy użytkownika do stanu
          this.storage?.addUser(new User<UserData>({
            id: eventUser.id as string,
            firstName: "",
            lastName: "",
            username: eventUser.username as string,
            email: "",
            avatar: eventUser.avatar as string,
            bio: "",
            presence: new Presence({
              status: UserStatus.Available
            }),
            data: {
              type: eventUser.type
            }
          }));

          this.updateState();

        } else if (state === /*UserState.Disconnected*/ 3) {

          if (this.storage) {
            
            this.storage?.setPresence(eventUser.id, new Presence({status: UserStatus.Dnd}));
            
            // Wstawiamy wiadomość konsultantowi, że klient się rozłączył (tylko do konwersacji, które jeszcze nie są rozłączone)
            const conversations = this.storage.getState().conversations.filter(c => c.participantExists(eventUser.id) && c.data.state !== 100 );
            
            conversations.forEach(c => {
              // Dodaję ręczną wiadomość, że użytkownik się rozłączył
              this.eventHandlers.onMessage(new MessageEvent({
                message: {
                  id: nanoid(),
                  status: MessageStatus.Seen,
                  contentType: MessageContentType.TextHtml,
                  direction: MessageDirection.Incoming,
                  /* @ts-ignore */ // TODO: Wygląda na to, że całość jest źle ogarnięta bo tutaj żeby to było zgodne z use-chat to content powinien byc obiektem
                  content: "KLIENT SIĘ ROZŁĄCZYŁ",
                  senderId: eventUser.id,
                }, conversationId: c.id
              }));
            });
            
          }
        }

      });
      
      this.socket.on("Typing", (evt: SendTypingServiceParams) => {
        this.eventHandlers.onUserTyping(new UserTypingEvent(evt));
      })
      
      this.socket.on("message", (evt: {
        conversationId: string,
        message: {
          id: string;
          content: MessageContent<MessageContentType.TextHtml>;
          senderId: string;
          direction: string;
          status: string
        }
      }) => {

        const {message: msg, conversationId} = evt;

        const state = this.storage?.getState();

        if (state) {
          const message = new ChatMessage({
            id: msg.id,
            status: MessageStatus.DeliveredToDevice, // It's clear that status is now delivered, so it can be set directly
            senderId: msg.senderId,
            contentType: MessageContentType.TextHtml,
            direction: state.currentUser?.id === msg.senderId ? MessageDirection.Outgoing : MessageDirection.Incoming,
            content: msg.content,
          });
          
          
          // NVDA czyta od razu pojawiające się wiadomości w aria-live,
          // co powoduje, że dźwięk wiadomości nie jest odtwarzany.
          // Dlatego trzeba najpierw odtworzyć dźwięk, 
          // poczekać na zakończenie odtwarzania i wtedy dodać wiadomość 
          this.newMessageAudio.onended = () => {
            this.eventHandlers.onMessage(new MessageEvent({message, conversationId}));
          }

          // Może być błąd odgrywania, jeżeli użytkownik nie kliknął wcześniej na stronie (np po odświeżeniu strony)
          // wtedy nie wykona się zdarzenie "ended", dlatego musimy tutaj dodać wiadomość
          this.newMessageAudio.play().catch(err => {
            this.eventHandlers.onMessage(new MessageEvent({message, conversationId}));
          });
          
        }

      });
      
      this.socket.on("Actions", (evt)  => onActions.call(this, evt, true) );

    });

  }

  register({id, username, avatar, status = UserStatus.Available}: RegisterParams): Promise<RegisterResponse> {
    
    return new Promise((resolve) => {

      this.socket?.emit("register", {id, username, avatar, status}, (response: any) => {

        this.registered = true;
        const actions = response.actions;
        if (actions) {
          onActions.call(this, { actions }, true );
        }
        resolve(response);

      });

    });

  }

  /**
   * Get access token from server
   */
  async getToken(params: string | undefined = "") {
    if (!this.initialized) {
      throw "Not initialized. Please use the initialize method first";
    }

    try {
      const res = await fetch(`${this.api}/get-token${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include"
      });

      if (res.body) {

        const resObj = await res.json();
        this.accessToken = resObj.accessToken;

        return {
          result: this.accessToken ? true : false,
          accessToken: this.accessToken
        };

      } else {
        return {
          result: false, accessToken: ""
        };
      }
    } catch (err) {
      return {
        result: false, accessToken: ""
      };
    }
  }

  /**
   * Get own data from session
   */
  getUserData() {
    return new Promise((resolve) => {
      
      this.socket?.emit("getUserData", {}, (response: any) => {

        if (response.result) {
          const user = response.user;

          this.storage?.setCurrentUser(new User<UserData>({
            id: user.id,
            presence: new Presence({
              status: user.status, 
              description: ""
            }),
            username: user.username,
            avatar: user.avatar,
            data: {
              type: user.type
            }
          }));

          // Update the state to reflect changes in the application 
          this.updateState();
        }
        // This is not necessary, because upon updateState the current user is set in the storage, so that it is accessible from the useChat hook,
        // but sometimes it's convenient to get a response directly from function
        resolve(response)
      });

    });
  }

  /**
   * Get contacts list from server
   * Use this method after register to obtain current contacts
   */
  getContacts() {
    
    return new Promise((resolve) => {

      this.socket?.emit("getContacts", {}, (response: any) => {

        console.log("getContacts", response);
        
        response.items.forEach((u: { id: string, username: string, avatar: string, status: number, type: UserType }) => {

          this.storage?.addUser(new User<UserData>({
            id: u.id,
            username: u.username,
            firstName: "",
            lastName: "",
            email: "",
            bio: "",
            avatar: u.avatar,
            presence: new Presence({status: u.status}),
            data: {
              type: u.type
            }

          }));

        });

        // Update the state to reflect changes in the application 
        this.updateState();

        // This is not necessary, because upon updateState the contacts are set in the storage so they are accessible from the useChat hook,
        // but sometimes it's convenient to get a response directly from function
        resolve(response);

      });

    })
  }

  /**
   * Get conversation list from server
   * Use this method after register to obtain current conversations
   * Note that you need to get contacts first
   */
  getConversations(): Promise<GetConversationResponse> {

    return new Promise((resolve) => {

      this.socket?.emit("getConversations", {}, (response: GetConversationResponse) => {

        console.log("getConversations", response);

        const state = this.storage?.getState();

        if (state) {
          
          response.items.forEach((c) => {

            this.storage?.addConversation(new Conversation(
              {
                id: c.id,
                participants: c.participants.map(p => new Participant({
                  id: p,
                  role: new ConversationRole([])
                })),
                draft: "",
                typingUsers: new TypingUsersList({items: []}),
                unreadCounter: 0,
                readonly: c.readonly,
                data: c.data
              }
            ));

            c.messages.forEach((msg) => {
              const message = new ChatMessage({
                id: msg.id,
                status: MessageStatus.DeliveredToDevice, // It's clear that status is now delivered, so it can be set directly
                senderId: msg.senderId,
                contentType: MessageContentType.TextHtml,
                direction: state.currentUser?.id === msg.senderId ? MessageDirection.Outgoing : MessageDirection.Incoming,
                content: msg.content,
              });

              this.eventHandlers.onMessage(new MessageEvent({message, conversationId: c.id}));

            });
            
            this.storage?.setUnread(c.id, 0);

          });

          // Update the state to reflect changes in the application
          this.updateState();

          resolve(response);

        }
      })
    })

  }


  /**
   * Get messages for conversation
   */
  getMessages({conversationId}: { conversationId: string }) {
    
    return new Promise((resolve) => {

      this.socket?.emit("getMessages", {conversationId}, (response: any) => {

        console.log("getMessages", response);

        const state = this.storage?.getState();

        if (state) {
          
          response.items.forEach((msg: { id: string, content: MessageContent<MessageContentType.TextHtml>, senderId: string }) => {
            console.log("Iteruję", msg);
            const message = new ChatMessage({
              id: msg.id,
              status: MessageStatus.DeliveredToDevice, // It's clear that status is now delivered, so it can be set directly
              senderId: msg.senderId,
              contentType: MessageContentType.TextHtml,
              direction: state.currentUser?.id === msg.senderId ? MessageDirection.Outgoing : MessageDirection.Incoming,
              content: msg.content,
            });

            this.eventHandlers.onMessage(new MessageEvent({message, conversationId}));

            // calling updateState is not needed, because onMessage callback does it internally

          });

        }

        // This is not necessary, because upon updateState the messages are set in the storage, so they are accessible from the useChat hook,
        // but sometimes it's convenient to get a response directly from function
        resolve(response);

      });

    })
  }

  acceptConversation(params: AcceptConversationRequest): Promise<AcceptConversationResponse> {

    return new Promise((resolve) => {
      this.socket?.emit("acceptConversation", params, (response: AcceptConversationResponse) => {
        resolve(response);
      });
    });
  }

  rejectConversation(params: RejectConversationRequest): Promise<RejectConversationResponse> {
    return new Promise((resolve) => {
      this.socket?.emit("rejectConversation", params, (response: RejectConversationResponse) => {
        
        const actions = response.actions;
        if (actions) {
          onActions.call(this, { actions }, true );
        }
        resolve(response);
      });
    });
  }

  closeConversation(params: CloseConversationRequest): Promise<CloseConversationResponse> {

    return new Promise((resolve) => {
      this.socket?.emit("closeConversation", params, (response: CloseConversationResponse) => {
        resolve(response);

      });
    });
  }
  
  sendMessage(params: SendMessageServiceParams): void {
    this.socket?.emit("message", params);
  }

  sendTyping(params: SendTypingServiceParams): void {
    this.socket?.emit("Typing", params)
  }

  disconnect(): void {
    this.socket?.disconnect();
  }

}