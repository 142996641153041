import {KeyboardEvent, useCallback, useRef, useState} from "react";

export const useDetectVirtualKeyboard = () => {

  const virtualDetectionCompleted = useRef(false);
  
  const [isVirtualKeyboard, setIsVirtualKeyboard] = useState(false);

  const onKeyDown = useCallback((evt:KeyboardEvent<HTMLDivElement>) => {
    
    if ( !virtualDetectionCompleted.current ) {

      // Wirtualna klawiatura na mobile zwraca wartości klucza jako "Unidentified"
      // więc możemy po tym określić w jaki sposób ma się zachowywać pole eydcji wiadomości
      if ( evt.key === "Unidentified" ) {
        setIsVirtualKeyboard(true);
      }

      if ( evt.key !== "Enter" && evt.key !== "Backspace" ) { // Bo enter i backspace jest na mobilce, więc trzeba czekać na inny klawisz
        virtualDetectionCompleted.current = true;
      }
    }
  },[]);
  
  return { 
    onKeyDown: virtualDetectionCompleted.current ? undefined : onKeyDown,
    isVirtualKeyboard
  }
  
}