import {useChat, User} from "@chatscope/use-chat";
import type {ConversationId} from "@chatscope/use-chat";
import type {ReactNode} from "react";

/**
 * @param conversationId
 * @param fnc Function receiving currentTyping user as parameter. Value returned from this function will be returned by the hook
 * @returns {{content: *}}
 */
export const useTypingIndicator = (conversationId: ConversationId | undefined, fnc: (typingUser?:User) => ReactNode) => {
  
  const { getConversation, getUser } = useChat();
  
  if ( conversationId ) {
    const conversation = getConversation(conversationId);

    if (conversation) {
      
      const typingUsers = conversation.typingUsers;
      
      if (typingUsers.length > 0) {

        const typingUserId = typingUsers.items[0].userId;

        // Check if typing user participates in the conversation
        if (conversation.participantExists(typingUserId)) {
          
          const typingUser = getUser(typingUserId);

          if (typingUser) {

            return {
              found: true,
              typingIndicator: {
                content: fnc(typingUser)
              }
            };
            
          }

        }

      }

    }
    
  }

  // Jeżeli nic nie zostało znalezione, to zwracam pustą wartość
  // bo w pewnych przypadkach związanych z czytnikami głosowymi chcemy nadal generować content, ale pusty
  return {
    found: false,
    typingIndicator: {
      content: fnc(undefined)
    }
  };
  
};