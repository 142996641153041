import {DiChatAdapter} from "../DiChatAdapter";
import {runActions} from "../runActions";

interface ActionsEvent {
  actions: any;
}

export function onActions(this:DiChatAdapter, actionsEvent:ActionsEvent, updateState: boolean) {

  console.log("[di-chat-adapter] Receiver Actions event", actionsEvent);
  // Run internal actions
  runActions.call(this, actionsEvent.actions, updateState);
  
  // Call external actions callback for application actions
  if ( this.onEvent ) {
    console.log("[di-chat-adapter] Run onEvent", this.onEvent);
    this.onEvent(actionsEvent);
  }
  
}