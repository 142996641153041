import type {IChatService, IStorage, UpdateState, ChatEvent} from "@chatscope/use-chat";
import {ChatEventType} from "@chatscope/use-chat/dist/enums";
import {ChatEventHandler, SendMessageServiceParams, SendTypingServiceParams} from "@chatscope/use-chat";

type EventHandlers = {
    onMessage: ChatEventHandler<
        ChatEventType.Message,
        ChatEvent<ChatEventType.Message>
        >;
    onConnectionStateChanged: ChatEventHandler<
        ChatEventType.ConnectionStateChanged,
        ChatEvent<ChatEventType.ConnectionStateChanged>
        >;
    onUserConnected: ChatEventHandler<
        ChatEventType.UserConnected,
        ChatEvent<ChatEventType.UserConnected>
        >;
    onUserDisconnected: ChatEventHandler<
        ChatEventType.UserDisconnected,
        ChatEvent<ChatEventType.UserDisconnected>
        >;
    onUserPresenceChanged: ChatEventHandler<
        ChatEventType.UserPresenceChanged,
        ChatEvent<ChatEventType.UserPresenceChanged>
        >;
    onUserTyping: ChatEventHandler<
        ChatEventType.UserTyping,
        ChatEvent<ChatEventType.UserTyping>
        >;
    [key: string]: any;
};

export abstract class BaseChatAdapter implements IChatService {

    storage?: IStorage;
    updateState: UpdateState;

    eventHandlers: EventHandlers = {
        onMessage: () => {},
        onConnectionStateChanged: () => {},
        onUserConnected: () => {},
        onUserDisconnected: () => {},
        onUserPresenceChanged: () => {},
        onUserTyping: () => {},
    };

    constructor(storage:IStorage, updateState:UpdateState) {
        this.storage = storage;
        this.updateState = updateState;
    }
    
    off<T extends ChatEventType, H extends ChatEvent<T>>(evtType: T, evtHandler: ChatEventHandler<T, H>): void {
        const key = this.getEventKey(evtType);
        if (key in this.eventHandlers) {
            this.eventHandlers[key] = () => {};
        }
    }

    on<T extends ChatEventType, H extends ChatEvent<T>>(evtType: T, evtHandler: ChatEventHandler<T, H>): void {
        const key = this.getEventKey(evtType);

        if (key in this.eventHandlers) {
            this.eventHandlers[key] = evtHandler;
        }
    }

    protected getEventKey<T extends ChatEventType>(evtType: T) {
        return `on${evtType.charAt(0).toUpperCase()}${evtType.substring(1)}`;
    }
    
    public abstract sendMessage(params: SendMessageServiceParams): void;

    public abstract sendTyping(params: SendTypingServiceParams): void;
    
}
