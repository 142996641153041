import {ConversationData, DiChatAdapter, UserType} from "../DiChatAdapter";
import {
  ChatMessage,
  Conversation,
  ConversationRole,
  MessageContent,
  MessageContentType, MessageDirection, MessageStatus,
  Participant,
  TypingUsersList
} from "@chatscope/use-chat";

export function onConversationStateChanged (this:DiChatAdapter, evt: {
  state: number, conversation: {
    id: string, name: string, participants: {id:string, type:UserType}[],
    messages: { id: string, content: MessageContent<MessageContentType.TextHtml>, senderId: string }[], readonly: boolean, data: ConversationData
  }
}) {
  
  console.log("[di-chat-adapter] ConversationStateChanged", evt);

  if (this.storage) {

    const state = evt.state;
    const eventConversation = evt.conversation;

    console.log(`[di-chat-adapter] ConversationState ${state}`);
    
    if (state === /* ConversationState.Created */ 0 || state === /* ConversationState.Directed */ 2) {
      this.storage.addConversation(
        new Conversation<ConversationData>({
          id: eventConversation.id,
          unreadCounter: eventConversation.messages.length,
          typingUsers: new TypingUsersList({items: []}),
          draft: "",
          participants: evt.conversation.participants.map(({id}) =>
            new Participant({
              id,
              role: new ConversationRole([])
            })
          ),
          readonly: eventConversation.readonly,
          data: eventConversation.data
        })
      )

      eventConversation.messages.forEach(m => this.storage?.addMessage(new ChatMessage({
        id: m.id,
        senderId: m.senderId,
        content: m.content,
        status: MessageStatus.Sent,
        direction: MessageDirection.Incoming,
        contentType: MessageContentType.TextHtml,
      }), eventConversation.id, false));

      if ( state === /* ConversationState.Directed */ 2 && this.autoAccept === true ) {
        this.acceptConversation({
          conversationId: eventConversation.id
        });
      }
      
      this.updateState();

    } else if (state === 3 /* ConversationState.Active */) {

      const [conversationFromStorage] = this.storage.getConversation(eventConversation.id);
      console.log(`[di-chat-adapter] Conversation from storage`, conversationFromStorage);

      if (conversationFromStorage) {
        this.storage.updateConversation(new Conversation<ConversationData>({
          id: eventConversation.id,
          unreadCounter: conversationFromStorage.unreadCounter,
          typingUsers: conversationFromStorage.typingUsers,
          draft: conversationFromStorage.draft,
          participants: evt.conversation.participants.map(({id}) =>
            new Participant({
              id,
              role: new ConversationRole([])
            })
          ),
          readonly: eventConversation.readonly,
          data: eventConversation.data
        }));

        this.updateState();

      } else {
        this.storage.addConversation(
          new Conversation<ConversationData>({
            id: eventConversation.id,
            unreadCounter: eventConversation.messages.length,
            typingUsers: new TypingUsersList({items: []}),
            draft: "",
            participants: evt.conversation.participants.map(({id}) =>
              new Participant({
                id,
                role: new ConversationRole([])
              })
            ),
            readonly: eventConversation.readonly,
            data: eventConversation.data
          })
        )

        eventConversation.messages.forEach(m => this.storage?.addMessage(new ChatMessage({
          id: m.id,
          senderId: m.senderId,
          content: m.content,
          status: MessageStatus.Sent,
          direction: MessageDirection.Incoming,
          contentType: MessageContentType.TextHtml,
        }), eventConversation.id, false));

        this.updateState();

      }
    } else if (state === 100 /* ConversationState.Ended */) {

      const [conversationFromStorage] = this.storage.getConversation(eventConversation.id)
      
      if (conversationFromStorage) {
        
        if (conversationFromStorage.data.state === 2 /* Directed */ ) {
          
          this.storage.removeConversation(eventConversation.id, true);
          this.updateState();
          
        } else {

          this.storage.updateConversation(new Conversation<ConversationData>({
            id: eventConversation.id,
            unreadCounter: conversationFromStorage.unreadCounter,
            typingUsers: conversationFromStorage.typingUsers,
            draft: conversationFromStorage.draft,
            participants: evt.conversation.participants.map(({id}) =>
              new Participant({
                id,
                role: new ConversationRole([])
              })
            ),
            readonly: eventConversation.readonly,
            data: eventConversation.data
          }));
          
        }
        
        this.updateState();

      }
    }

  }
}
  
