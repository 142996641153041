/**
 * @this DiChatAdapter
 * @param actions
 */
import {DiChatAdapter} from "./DiChatAdapter";

export function runActions(this: DiChatAdapter, actions:any[], updateState: boolean) {
  
    actions.forEach( action => {
        const {type, params} = action;
        if ( type === "removeConversation" ) {
          this.storage?.removeConversation(params.conversationId, true);
        } else if ( type === "removeUser" ) {
            this.storage?.removeUser(params.userId);
        }
    });
    
    if ( updateState ) {
      this.updateState();
    }
    
} 