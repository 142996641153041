import {UserStatus} from "@chatscope/use-chat";
import {UserStatus as UserStatusUI} from "@chatscope/chat-ui-kit-react";

// Maps status from useChat library to status name from chat-ui-kit library
export const statusMap:Record<UserStatus,UserStatusUI|undefined> = {
  [UserStatus.Available]: "available",
  [UserStatus.Unavailable]: "unavailable",
  [UserStatus.Away]: "away",
  [UserStatus.Dnd]: "dnd",
  [UserStatus.Invisible]: "invisible",
  [UserStatus.Eager]: "eager",
  [UserStatus.Unknown]: undefined
};

// Maps conversation status from server to status name from chat-ui-kit library
export const conversationStatusMap:Record<number, UserStatusUI|undefined> = {
  0: undefined,
  1: undefined,
  2: "away",
  3: "available",
  100: "dnd"
}